<template>
  <div class="login-from">
    <div class="card card-container">
      <img
        id="profile-img"
        class="profile-img-card"
        :src="logo"
      >
      <p
        id="profile-name"
        class="profile-name-card"
      />
      <form
        class="form-signin"
        @submit.prevent="submitForm"
      >
        <span
          id="reauth-email"
          class="reauth-email"
        />
        <div
          class="row"
          v-if="has_error"
        >
          <div class="col">
            <div class="alert alert-danger">
              {{ error_msg }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <input
              type="text"
              id="inputEmail"
              class="form-control"
              placeholder="Email"
              v-model="username"
              required
              autofocus
              :readonly="usernameReadOnly"
            >
          </div>
        </div>
        <div
          class="row"
          v-if="validate_code"
        >
          <div class="col form-group">
            <input
              type="number"
              class="form-control"
              id="code_validation"
              name="code_validation"
              placeholder="Code Activation"
              required
              autofocus
              v-model="code"
            >
          </div>
        </div>
        <div
          class="row"
          v-if="set_password"
        >
          <div class="col form-group">
            <input
              type="password"
              id="password"
              class="form-control"
              placeholder="Mot de passe"
              required
              autofocus
              v-model="password"
            >
            <input
              type="password"
              id="confirm_password"
              class="form-control"
              placeholder="Confirmation Mot de passe"
              required
              v-model="confirm_password"
            > 
          </div>
        </div>
        <div
          class="row"
          v-if="login"
        >
          <div class="col-12 form-group">
            <input
              type="password"
              id="password"
              class="form-control"
              placeholder="Password"
              required
              autofocus
              v-model="password"
            >
          </div>
          <div
            id="remember"
            class="col-12 checkbox"
          >
            <label>
              <input
                type="checkbox"
                value="remember-me"
              > Remember me
            </label>
          </div>
        </div>
        <loading-button 
          :btn-class="'btn-lg btn-success btn-block btn-signin'" 
          v-if="loading"
        />
        <button 
          v-else
          class="btn btn-lg btn-success btn-block btn-signin"
          type="submit"
        >
          {{ loginText }}
        </button>
      </form><!-- /form -->
      <a
        href="#"
        class="forgot-password"
      >
        Mot de passe oublié?
      </a>
      <div class="row">
        <div class="copy-right">
          Powerd By Groupe AGRO-EXPERTISE
        </div>
      </div>
    </div><!-- /card-container -->
  </div><!-- /login-form -->
</template>
<script>
import {LOGIN, CHECK_USERNAME, VALIDATE_ACTIVATION_CODE, SET_PASSWORD} from '../graphql/user'
import {mapMutations} from 'vuex'
import LoadingButton from '../components/loadingButtom.vue'
import { saveToken } from '../../src/plugins/localstorage'
import { TOKEN_EXPIRE, USERID } from '../constants/app'
const logo = require('../assets/img/logo256.png')
export default {
    name: 'Login',
    components: {LoadingButton},
    data(){
        return {
            username: null,
            password: null,
            confirm_password: null,
            code: null,
            uid: null,
            check_user: true,
            validate_code: false,
            set_password: false,
            login: false,
            has_error: false,
            error_msg: null,
            loading: false,
            logo
        }
    },
    methods: {
        ...mapMutations({
            loggedUser: 'auth/LOGIN_USER'
        }),
        submitForm(){
            this.has_error = false
            this.loading = true
            if(this.check_user){
                this.$apollo.mutate({
                    mutation: CHECK_USERNAME,
                    variables: {email: this.username},
                    update: (store, {data: {checkUser:user}}) => {
                        console.log(user)
                        if(user.uid !== null) this.check_user = false
                        this.uid = user.uid
                        if(!user.firstConnexion) {
                            this.set_password = false
                            this.login = true
                            // this.validate_code = true
                        }
                        else{
                            // this.set_password = true
                            this.login = false
                            this.validate_code = true
                        }
                    }
                }).then((data) => {
                    // Result
                    console.log(data)
                    this.loading = false
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                    this.loading = false
                })
            }
            if(this.validate_code){
                 this.$apollo.mutate({
                    mutation: VALIDATE_ACTIVATION_CODE,
                    variables: {email: this.username, codeActivation: parseInt(this.code)},
                    update: (store, {data: {validateCode }}) => {
                        console.log(validateCode)
                        if(validateCode !== null) {
                            this.validate_code = false
                            this.set_password = true
                            this.login = false
                            this.uid = validateCode
                        }
                    }
                }).then((data) => {
                    // Result
                    console.log(data)
                    this.loading = false
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                    this.loading = false
                })
            }
            if(this.set_password){
                if(this.confirm_password !== this.password) {
                    console.error("Password not match")
                    return
                }
                this.$apollo.mutate({
                    mutation: SET_PASSWORD,
                    variables: {"password": this.password, "uid": this.uid},
                    update: (store, {data: {setPassword}}) => {
                      this.loggedUser(setPassword.token)
                      saveToken(USERID, setPassword.uid) 
                      saveToken(TOKEN_EXPIRE, setPassword.expireAt)
                      location.reload()
                        
                    }
                }).then((data) => {
                    // Result
                    console.log(data)
                    this.loading = false
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                    this.loading = false
                })
            }
            if(this.login){
              this.$apollo.mutate({
              mutation: LOGIN,
              variables: {
                'email': this.username,
                'password': this.password
              },
              update: (store, {data:{login}}) => {
                console.log(login)
                this.loggedUser(login.token)
                saveToken(USERID, login.uid) 
                saveToken(TOKEN_EXPIRE, login.expireAt)
                location.reload()
              },
            }).then((data) => {
                  // Result
                console.log(data)
                this.loading = false
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
                  this.loading = false
              })
            }
        }
    },
    computed: {
        loginText () {
            if(this.check_user) return 'Identification'
            if(this.validate_code) return 'Validation'
            if(this.set_password) return 'Enregistrer'
            else return 'Connexion'
        },
        usernameReadOnly(){
            if(this.validate_code | this.set_password | this.login) return true
            return false
        }
    }
}
</script>
<style  scoped>
/*
 * Specific styles of signin component
 */
/*
 * General styles
 */
.login-from{
    margin: 0px;
    height:100vh;
    background-image: linear-gradient(to bottom, rgba(239, 240, 245, 0), rgba(1, 1, 17, 0.73)), url('../assets/img/background-caseac.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    
}

.card-container.card {
    width: 450px;
    padding: 50px 50px;
}

.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

/*
 * Card component
 */
.card {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    /* background-color: #F7F7F7; */
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: auto;
    /* margin-top: 50px; */
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
    width: 150px;
    height: 150px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}
.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.form-signin #inputEmail,
.form-signin #inputPassword {
    direction: ltr;
    height: 44px;
    font-size: 16px;
}
.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: rgb(104, 145, 162);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
}

.btn.btn-signin {
    background-color: #1c6100;
    /* background-color: rgb(104, 145, 162); */
    /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33)); */
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: rgb(12, 97, 33);
}

.forgot-password {
    color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    color: rgb(12, 97, 33);
}
.copy-right{
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 11px;
}
</style>